var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-list',[_vm._l((_vm.documents),function(docItem,index){return [_c('v-divider',{key:index}),_c('v-list-item',{key:docItem.id},[_c('v-list-item-icon',{staticClass:"d-flex flex-column align-center"},[_c('v-icon',{attrs:{"color":_vm.fileColor(docItem)}},[_vm._v(_vm._s(docItem.url ? 'mdi-link' : _vm.fileIcon(docItem)))]),_c('span',{staticClass:"caption grey--text"},[_vm._v(_vm._s(docItem.url ? '' : docItem.fileExtension))])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(docItem.title)}}),(docItem.description)?_c('span',{staticClass:"body-2",domProps:{"innerHTML":_vm._s(docItem.description)}}):_vm._e(),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.formatDate(docItem.lastModifiedDate))+", "+_vm._s(docItem.lastModifiedTime)+" | "+_vm._s(docItem.visibility ? docItem.visibility.description : '')+" "),(!docItem.url)?_c('span',[_vm._v("| "+_vm._s(docItem.size))]):_vm._e()])],1),_c('v-list-item-action',{staticClass:"d-flex flex-row"},[_c('CopyButton',{attrs:{"value":docItem.id}}),(docItem.url)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","href":docItem.url,"target":"_blank"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-link")])],1)]}}],null,true)},[_c('span',[_vm._v("URL öffnen")])]):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":!docItem.available,"icon":""},on:{"click":function($event){return _vm.download(docItem.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download")])],1)]}}],null,true)},[_c('span',[_vm._v("Datei herunterladen")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"success","to":{
                name: 'DocumentEdit',
                params: { catId: docItem.category.id, docId: docItem.id },
              }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Dokument bearbeiten")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"icon":"","disabled":!!docItem.url,"color":docItem.available ? 'success' : 'red',"to":{
                name: 'DocumentUpload',
                params: { catId: docItem.category.id, docId: docItem.id },
              }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-paperclip")])],1)]}}],null,true)},[_c('span',[_vm._v("Datei ändern")])])],1)],1)]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }