<template>
  <v-list>
    <template v-for="(docItem, index) in documents">
      <v-divider :key="index" />
      <v-list-item :key="docItem.id">
        <v-list-item-icon class="d-flex flex-column align-center">
          <v-icon :color="fileColor(docItem)">{{
            docItem.url ? 'mdi-link' : fileIcon(docItem)
          }}</v-icon
          ><span class="caption grey--text">{{
            docItem.url ? '' : docItem.fileExtension
          }}</span>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title v-html="docItem.title"></v-list-item-title>
          <span
            v-if="docItem.description"
            class="body-2"
            v-html="docItem.description"
          ></span>
          <v-list-item-subtitle>
            {{ formatDate(docItem.lastModifiedDate) }},
            {{ docItem.lastModifiedTime }} |
            {{ docItem.visibility ? docItem.visibility.description : '' }}
            <span v-if="!docItem.url">| {{ docItem.size }}</span>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action class="d-flex flex-row">
          <CopyButton :value="docItem.id" />

          <v-tooltip top v-if="docItem.url">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                :href="docItem.url"
                target="_blank"
                v-bind="attrs"
                v-on="on"
                ><v-icon>mdi-link</v-icon></v-btn
              >
            </template>
            <span>URL öffnen</span>
          </v-tooltip>
          <v-tooltip top v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="!docItem.available"
                icon
                @click="download(docItem.id)"
                v-bind="attrs"
                v-on="on"
                ><v-icon>mdi-download</v-icon></v-btn
              >
            </template>
            <span>Datei herunterladen</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="success"
                :to="{
                  name: 'DocumentEdit',
                  params: { catId: docItem.category.id, docId: docItem.id },
                }"
                v-bind="attrs"
                v-on="on"
                ><v-icon>mdi-pencil</v-icon></v-btn
              >
            </template>
            <span>Dokument bearbeiten</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-2"
                icon
                :disabled="!!docItem.url"
                :color="docItem.available ? 'success' : 'red'"
                :to="{
                  name: 'DocumentUpload',
                  params: { catId: docItem.category.id, docId: docItem.id },
                }"
                v-bind="attrs"
                v-on="on"
                ><v-icon>mdi-paperclip</v-icon></v-btn
              >
            </template>
            <span>Datei ändern</span>
          </v-tooltip>
        </v-list-item-action>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
import { formatDate } from 'common/utils/date.js';
import CopyButton from '@/components/CopyButton.vue';
export default {
  components: { CopyButton },
  props: ['documents'],
  methods: {
    async download(id) {
      await this.apiDownload({ resource: 'document/file', id: id });
    },
    formatDate,
    fileIcon(item) {
      if (item.url != null) {
        return 'mdi-link';
      }
      if (item.fileExtension == null) return 'mdi-file-outline';
      if (item.fileExtension.startsWith('do')) {
        return 'mdi-file-word-outline';
      }
      if (item.fileExtension.startsWith('xl')) {
        return 'mdi-file-excel-outline';
      }
      if (item.fileExtension.startsWith('pp') || item.fileExtension == 'thmx') {
        return 'mdi-file-powerpoint-outline';
      }
      switch (item.fileExtension) {
        case 'zip':
          return 'mdi-zip-box-outline';
        case 'pdf':
          return 'mdi-file-document-outline';
        case 'gif':
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'tif':
        case 'svg':
          return 'mdi-file-image-outline';

        default:
          return 'mdi-file-outline';
      }
    },
    fileColor(item) {
      if (item.url != null) {
        return '';
      }
      if (item.fileExtension == null) return '';
      if (item.fileExtension.startsWith('do')) {
        return '#035AC1';
      }
      if (item.fileExtension.startsWith('xl')) {
        return '#107B41';
      }
      if (item.fileExtension.startsWith('pp') || item.fileExtension == 'thmx') {
        return '#D4512D';
      }
      switch (item.fileExtension) {
        case 'zip':
          return 'yellow';
        case 'pdf':
          return '#f40f02';
        case 'gif':
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'tif':
        case 'svg':
          return 'primary';

        default:
          return '';
      }
    },
  },
};
</script>
